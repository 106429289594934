import 'twin.macro';
import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useStoreActions } from 'easy-peasy';
import CurrencyFormat from '../global/CurrencyFormat';

import { Heading, Button, QuantityButton } from '../atoms';
import { PortableText } from '../elements';
import SanityImage from '../global/SanityImage';
import { makeId } from '../../utils/helper';

const ProductSidebar = ({ data }) => {
  const [quantity, setQuantity] = useState(1);
  const addToCart = useStoreActions((actions) => actions.cart.add);
  if (!data) return null;
  const handleAddToCart = () => {
    const itemData = {
      itemId: makeId(5),
      id: data._id,
      title: data.title,
      brand: data?.brand?.title || '',
      slug: data.slug ? data.slug.current : '',
      price: data.price,
      image: data.image ? data.image.asset.url : '',
      quantity,
    };

    addToCart(itemData);
    setTimeout(() => navigate('/cart'), 600);
  };
  const checkIsForShop =
    data.productVisibility === 'shop' || data.productVisibility === 'both';

  return (
    <div>
      <div tw="flex flex-row justify-between items-center">
        <Heading variant="base" tw="mb-4 border-b my-6 mr-5 border-black w-4/6	">
          {data.title}
        </Heading>

        <div tw="object-contain w-4/12">
          {data.brand && data.brand.image && data.brand.image.asset && (
            <SanityImage
              fluid={data.brand.image.asset.gatsbyImageData}
              alt={data.brand.title || data.title}
            />
          )}
        </div>
      </div>
      <div tw="px-6 py-4 mt-4 bg-white">
        {data.price && (
          <Heading tw="mt-1">
            <CurrencyFormat amount={data.price} />
          </Heading>
        )}
        {data?._rawBody && (
          <PortableText body={data._rawBody} tw="!px-0 !py-1" />
        )}
        {/* //counter  */}
        {data && data.price && checkIsForShop ? (
          <div tw=" h-10 w-32">
            <div tw="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
              <QuantityButton
                onClick={() => {
                  if (quantity > 1) {
                    setQuantity(quantity - 1);
                  }
                }}
              >
                <span tw="m-auto text-2xl font-thin">−</span>
              </QuantityButton>
              <p tw="outline-none justify-center focus:outline-none text-center w-full bg-gray-300 font-semibold  hover:text-black focus:text-black   flex items-center text-gray-700  outline-none">
                {quantity}
              </p>
              <QuantityButton onClick={() => setQuantity(quantity + 1)}>
                <span tw="m-auto text-2xl font-thin">+</span>
              </QuantityButton>
            </div>
          </div>
        ) : (
          ''
        )}
        {data &&
        data.price &&
        (data.productVisibility === 'shop' ||
          data.productVisibility === 'both') ? (
          <Button type="button" onClick={handleAddToCart}>
            Add To Cart
          </Button>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default ProductSidebar;
