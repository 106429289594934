/* eslint-disable no-plusplus */
export default function rangeMap(n, fn) {
  const arr = [];
  while (n > arr.length) {
    arr.push(fn(arr.length));
  }

  return arr;
}

export const makeId = (length) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
