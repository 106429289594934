import 'twin.macro';
import React from 'react';

import ImageGallery from 'react-image-gallery';

const ProductGallery = ({ data }) => {
  if (!data) return null;
  const isMobile = global.window ? global.window.innerWidth < 768 : false;
  const images = data
    ? data.map((image) => ({
        original: image?.asset?.url,
        thumbnail: image?.asset?.url,
      }))
    : [];
  return (
    <ImageGallery
      items={images}
      thumbnailPosition="bottom"
      showPlayButton={false}
      showNav={false}
      showThumbnails={!isMobile}
      showFullscreenButton={!isMobile}
      showBullets={isMobile}
      lazyLoad
    />
  );
};

export default ProductGallery;
