import 'twin.macro';
import React from 'react';
import { graphql } from 'gatsby';
import 'react-image-gallery/styles/css/image-gallery.css';

import config from '../utils/config';
import Seo from '../components/global/Seo';
import { Collapse, PortableText } from '../components/elements';
import Container from '../components/atoms/Container';
import Layout from '../components/Layout';
import HomeHero from '../components/home/HomeHero';
import ProductSidebar from '../components/product/ProductSidebar';
import ProductGallery from '../components/product/ProductGallery';

export const query = graphql`
  query ProductViewQuery($slug: String!) {
    sanityProduct(slug: { current: { eq: $slug } }) {
      _id
      title
      slug {
        current
      }
      productVisibility
      description
      _rawBody
      price
      image {
        asset {
          gatsbyImageData
          url
        }
      }
      images {
        _key
        asset {
          gatsbyImageData
          url
        }
      }
      collection {
        _id
        title
        slug {
          current
        }
      }
      brand {
        _id
        title
        slug {
          current
        }
        image {
          asset {
            url
            gatsbyImageData
          }
        }
      }

      features {
        _key
        title
        _rawBody
      }
      colors {
        title
        value
      }
    }
  }
`;

const ProductView = ({ data: productData }) => {
  const product = productData.sanityProduct;

  return (
    <Layout>
      <Seo
        title={product.title}
        description={`${product.description} at ${config.siteName}`}
        keywords={product.tag || product.title}
        image={product.image.asset.url}
      />

      <HomeHero
        title={product.title}
        fluid={
          product.image &&
          product.image.asset &&
          product.image.asset.gatsbyImageData
        }
        description={product.description}
      />
      <Container tw="max-w-none w-full bg-slate md:py-20">
        <div tw="relative grid items-start gap-8 grid-cols-1 overflow-x-hidden lg:grid-cols-12">
          <div tw="relative px-0 pb-0 box-border flex flex-col col-span-1 md:mx-0 md:col-span-8">
            <div tw="text-center h-full relative">
              <div tw="flex items-center justify-center overflow-x-hidden">
                <ProductGallery data={product.images} />
              </div>
            </div>
          </div>

          {/* // sidebar */}
          <div tw="flex flex-col col-span-1 mx-auto max-w-3xl w-full h-full md:col-span-4">
            <ProductSidebar data={product} />
          </div>
        </div>

        <div tw="mt-4 md:mt-10 w-full md:w-2/3 ">
          {product.features &&
            product.features.map((item) => (
              <Collapse title={item.title} key={item._key}>
                {item._rawBody && (
                  <PortableText tw="!px-0" body={item._rawBody} />
                )}
              </Collapse>
            ))}
        </div>
      </Container>
    </Layout>
  );
};

export default ProductView;
